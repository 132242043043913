let configs: any;
if ((window as any).configs) {
  configs = (window as any).configs;
}

if (!configs) {
  configs = typeof process.env === "undefined" ? undefined : process.env;
}
if (!configs) {
  global.console.error("Not defined params");
}

export const LOGISTIX_BASE_URL = configs.LOGISTIX_BASE_URL;
export const LOGISTIX_TOKEN = configs.LOGISTIX_TOKEN;



export default async function fetchJSON(url: string, options?: {[key: string]: any}) {
  const combinedURL = `${LOGISTIX_BASE_URL}${url}`;
  const defaultOptions = {
    headers: {
      Authorization: `${LOGISTIX_TOKEN}`,
    }
  }
  const response = await fetch(combinedURL, {...defaultOptions, ...options});
  if (response.ok) {
    return response.json();
  } else {
    throw response.statusText;
  }
}