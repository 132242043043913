import React from 'react';
import ReactDOM from 'react-dom';
import createHistory from "history/createBrowserHistory";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { 
  Switch, 
  Route, 
  Router
} from "react-router";

import * as serviceWorker from './serviceWorker';

import Home from "./features/home/components/Home";
import Auth from "./features/auth/components/auth";
import PickOrder from "./features/pickorder/components/PickOrder";
import CompleteOrder from "./features/completeorder/components/CompleteOrder";
import MainContainer from './maincontainer/MainContainer';
import LabelPrinter from './features/pickorder/components/printer/LabelPrinter';
import BulkPrinter from './features/pickorder/components/printer/BulkPrinter';

import "./index.css";

const theme = createMuiTheme({
  typography: {
    fontFamily: "Roboto",
  }
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <Router history={createHistory()}>
      <MainContainer>
        <Switch>
          <Route exact={true} path="/" component={Home}/>
          <Route path="/auth" component={Auth}/>
          <Route path="/pickorder" component={PickOrder}/>
          <Route path="/print/label" component={LabelPrinter}/>
          <Route path="/completeorder" component={CompleteOrder}/>
          <Route path="/print/airwaybill/external" component={BulkPrinter}/>
        </Switch>
      </MainContainer>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
