import React from "react";
import * as Barcode from "react-barcode";

import formatObjectKeys from "../../../../../helpers/formatObjectKeys";
import { formatDateTime } from "../../../../../helpers/formatDate";

import "../Airwaybill.css";

type recipientData = {
  name: string;
  address: string;
  phoneNumber: string;
  district: string;
  postCode: string;
  location_code: string;
};

type productLineDataJNE = {
  price: number;
  productCode: string;
  productFid: string;
  quantity: number;
  title: string;
  weight: number;
};

type customSpan = React.HTMLAttributes<HTMLSpanElement> & {
  flex: number;
};

export type JNEAirwaybillData = {
  orderDate: string;
  recipientData: recipientData;
  orderNumber: string;
  providerName: string;
  providerService: string;
  awbNumber: string;
  senderName: string;
  senderPhone: string;
  senderAddress: string;
  remarks: string;
  message: string;
  products: productLineDataJNE[];
  logo: string;
  customerSMCode: string; // cnx's code: 05SM
  smCode: string; // cnx's code CNX
  goodsType: string;
  woodPacking: string;
  insuranceFee: string;
  shippingCost: number;
  codAmount: string;
  senderLocationCode: string;
};

type airwaybillProp = {
  data: JNEAirwaybillData;
};

const Span = (props: customSpan) => (
  <span {...props} style={{ flex: props.flex, ...props.style }} />
);

const ProductLine = (product: productLineDataJNE, index: number) => {
  return (
    <div key={index} className="table-content-row">
      <Span flex={2}>{index + 1}</Span>
      <Span flex={5}>{product.productCode}</Span>
      <Span flex={10}>{product.title}</Span>
      <Span flex={6} />
      <Span flex={2}>{product.quantity}</Span>
    </div>
  );
};

// TO DO: Enable component when products have component items
// const Component = (allComponent: any) => {
//   return allComponent.map((comp: any, key: number) => {
//     return (
//       <>
//         <div className="table-content-row">
//           <Span flex={2}>{key}</Span>
//           <Span flex={5}>{comp.productCode}</Span>
//           <Span flex={10}>{comp.productFid}</Span>
//           <Span flex={6}></Span>
//           <Span flex={2}>{comp.quantity}</Span>
//         </div>
//         {/* {
//           comp.var ? (
//             comp.var.map((variant: any, key: number) => {
//               return (
//                 <div key={key} className="table-content-row">
//                   <Span flex={2}></Span>
//                   <Span flex={5}></Span>
//                   <Span flex={10}>{variant.number}</Span>
//                   <Span flex={6}>{variant.name}</Span>
//                   <Span flex={2}>{variant.qty}</Span>
//                 </div>
//               );
//             })
//           ) : null
//         } */}
//       </>
//     );
//   })
// }

const SellerDetails = (props: JNEAirwaybillData) => {
  const {
    orderDate,
    senderName,
    senderPhone,
    senderAddress,
    senderLocationCode,
  } = props;

  return (
    <div className="flex-column">
      <span>Order Date: {orderDate}</span>
      <span>SELLER: {senderName}</span>
      <span>{senderAddress}</span>
      <span>Telp. {senderPhone}</span>
      <span style={{ fontSize: 30, fontWeight: "bold" }}>
        {senderLocationCode}
      </span>
      <span style={{ margin: "auto" }}>Airwaybill</span>
    </div>
  );
};

const BuyerDetails = (recipientData: recipientData) => {
  return (
    <>
      <span>SHIPPING ADDRESS:</span>
      <span className="large-text-bold">{recipientData.name}</span>
      <span>{recipientData.address}</span>
      <span>
        {recipientData.district} {recipientData.postCode}
      </span>
      <span>Telp. {recipientData.phoneNumber}</span>
    </>
  );
};

export default function AirwaybillJNE(props: airwaybillProp) {
  const {
    recipientData,
    orderNumber,
    providerName,
    providerService,
    awbNumber,
    senderName,
    senderPhone,
    remarks,
    message,
    products,
    logo,
    goodsType,
    woodPacking,
    insuranceFee,
    shippingCost,
    codAmount,
  } = props.data;

  // Get sum of products weight
  let tempWeight = 0;
  products.map((product) => {
    tempWeight += product.weight;
  });

  return (
    <div className="container">
      <div className="header">
        <span className="header-text">
          Print date: #{formatDateTime(new Date())}
        </span>
      </div>
      <div className="print-area">
        <div className="flex-row-grow">
          <div className="print-content">
            <span style={{ display: "flex", height: 84 }}>
              <img
                style={{
                  margin: "0px 10px 0px 0px",
                  maxHeight: 74,
                  maxWidth: 185,
                  objectFit: "contain",
                }}
                src={require("../../../../../images/srcl-logo.png")}
              />
              <img
                style={{
                  maxHeight: 74,
                  maxWidth: 140,
                  objectFit: "contain",
                }}
                src={logo}
              />
            </span>
            <div style={{ lineHeight: 1 }}>Reference Number {orderNumber}</div>
            <Barcode
              width={1.35}
              format="CODE39"
              displayValue={true}
              value={orderNumber}
            />
            {SellerDetails(props.data)}
            <Barcode
              width={1}
              format="CODE39"
              displayValue={true}
              value={awbNumber}
            />
          </div>
          <div className="print-content">
            <span
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                height: 84,
              }}
            >
              <img
                alt="Courier Logo"
                width={180}
                src={
                  providerName
                    ? require(`../../../../../images/courier-logos/${`${providerName}`.toLowerCase()}-logo.png`)
                    : null
                }
                // This code is used to check sicepat logo quickly
                // src={require(`../../../../images/courier-logos/sicepat-logo.png`)}
              />
            </span>
            <span style={{ fontSize: 30, fontWeight: "bold" }}>
              {providerService}
            </span>
            <div className="flex-grow-column">
              {recipientData
                ? BuyerDetails(formatObjectKeys(recipientData))
                : "Tidak ada data penerima"}
              <div style={{ fontWeight: "bold", fontSize: 30 }}>
                {recipientData.location_code}
              </div>
              <div style={{ marginTop: 20 }}>Customer ID: 80519800</div>
              <div>Goods Type: {goodsType || "PAKET"}</div>
              <div>Packing kayu: {woodPacking || "NO"}</div>
              <div>Insurance: {insuranceFee || "NO"}</div>
              <div>Estimasi ongkir: {shippingCost || 0}</div>
              <div>COD Amount: {codAmount || 0}</div>
              <div>Weight: {tempWeight || 0} kg</div>
            </div>
          </div>
        </div>
        <div className="thank-you">THANK YOU FOR SHOPPING WITH US</div>
      </div>
      <div className="print-area" style={{ borderTop: 0 }}>
        <div className="print-area-notes">
          NOTES: {remarks ? remarks : "NONE"}<br/>
          BUYER NOTES: {message ? message : "NONE"}
        </div>
        <div className="table-container">
          <div className="table-header">
            <Span flex={2}>
              <b>No</b>
            </Span>
            <Span flex={5}>
              <b>SKU</b>
            </Span>
            <Span flex={10}>
              <b>Name</b>
            </Span>
            <Span flex={6}>
              <b />
            </Span>
            <Span flex={2}>
              <b>Qty</b>
            </Span>
          </div>
          <div className="table-content">
            {products
              ? products.map((product, index: number) => {
                  return ProductLine(formatObjectKeys(product), index);
                })
              : "Tidak ada data produk"}
          </div>
        </div>
      </div>
    </div>
  );
}
