import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { CircularProgress } from "@material-ui/core";
const { createApolloFetch } = require("apollo-fetch");

import Airwaybill, { airwaybillData } from "./Airwaybill";

import fetchJSON from "../../../../helpers/fetchJSON";

export type JRPCAirwaybillProductData = {
  name: string;
  sku: string;
  quantity: number;
};

export type JRPCAirwaybillData = {
  storeLogoUrl: string;
  shippingProvider: {
    name: string;
    logoUrl: string;
    packageService: string;
  };
  orderId: string;
  orderDate: number;
  awbNumber: string;
  notes: string;
  message: string;
  shipper: {
    name: string;
    phone: string;
  };
  receiver: {
    name: string;
    phone: string;
    address: string;
    district: string;
    zipCode: string;
  };
  products: JRPCAirwaybillProductData[];
  extraAttributes: {
    CustomerSMCode?: string;
    SMCode?: string;
    isDropship?: boolean;
  };
};

type BulkPrinterState = {
  printed: boolean;
  printDatas: airwaybillData[];
  data: any;
  dataIds: number[];
  siteID: string;
  error: boolean;
  errorMessage: string;
  isLoading: boolean;
  readyToPrint: boolean;
};

type BulkPrinterProp = RouteComponentProps & {
  // Add more prop type here
};

class BulkPrinter extends Component<BulkPrinterProp, BulkPrinterState> {
  state: BulkPrinterState = {
    printed: false,
    printDatas: [],
    data: {}, // To be used by singular label print, currently api doesn't support bulk printing
    error: false,
    errorMessage: "",
    dataIds: [],
    siteID: "",
    isLoading: true,
    readyToPrint: false
  };

  messageHandler(e: MessageEvent) {
    if (typeof e.data === "string") {
      const { ids, siteId } = JSON.parse(e.data)
      if (ids && siteId) {
        this.setState({ dataIds: ids, siteID: siteId }, async () => {
          const { dataIds, siteID } = this.state;
          const apolloFetch = createApolloFetch({
            uri: "/graphql"
          });
          apolloFetch({
            query: `query airwaybills($siteID: String!, $dataIDs: [Int]!) {
                airwaybills(siteID: $siteID, dataIDs: $dataIDs) {
                  logo
                  smCode
                  remarks
                  message
                  orderDate
                  senderName
                  awbNumber
                  senderPhone
                  orderNumber
                  providerName
                  providerService
                  customerSMCode
                  extraAttributes {
                    isDropship
                  }
                  recipientData {
                    phoneNumber
                    postCode
                    district
                    address
                    name
                  }
                  products {
                    productCode
                    productFid
                    quantity
                    title
                    price
                  }
                }
              }`,
            variables: {
              siteID,
              dataIDs: dataIds
            }
          })
            .then((data: any) => {
              this.setState({
                printDatas: data.data.airwaybills || [],
                readyToPrint: true,
                isLoading: false
              });
            })
            .catch((err: any) => {
              console.log(err);
            });
        });
        return;
      }
      return;
    }
    this.setState({ isLoading: false, errorMessage: "No data received" });
  }

  async componentDidMount() {
    const { pathname } = this.props.location;
    const userType = pathname.split("/")[3];

    if (userType === "external") {
      let opener = window.opener;
      opener && opener.postMessage("request_data", "*");
      await window.addEventListener("message", (e: MessageEvent) =>
        this.messageHandler(e)
      );
    } else {
      try {
        let result = await fetchJSON(`/createAWB/${pathname.split("/")[3]}`);
        if (result) {
          if (Object.keys(result).length > 1) {
            this.setState({ printDatas: result, isLoading: false });
          } else {
            this.setState({
              errorMessage: `Terjadi Kesalahan, Error Message: ${result.message}`,
              error: true,
              isLoading: false
            });
          }
        } else {
          throw new Error(result);
        }
      } catch (err) {
        this.setState({
          errorMessage: `Terjadi Kesalahan, Error Message: ${err}`,
          error: true,
          isLoading: false
        });
      }
    }

    if (!this.state.printed && this.state.readyToPrint) {
      setTimeout(() => window.print(), 500);
      this.setState({ printed: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", (e: MessageEvent) =>
      this.messageHandler(e)
    );
  }

  render() {
    const { printDatas, isLoading } = this.state;
    const docType = this.props.location.pathname.split("/")[2];

    if (docType === "label") {
      return "Label bulk print is yet to be supported";
    }

    if (docType === "airwaybill") {
      if (printDatas.length > 0) {
        return printDatas.map((data, index: number) => {
          return <Airwaybill key={index} data={data} />;
        });
      }
      return (
        <div style={{ textAlign: "center" }}>
          Gagal mendapatkan data. Ulangi beberapa saat lagi.
        </div>
      );
    }

    if (!isLoading) {
      return <div style={{ textAlign: "center" }}>Terjadi kesalahan</div>;
    }

    return (
      <div className="loading-container">
        <CircularProgress size={100} />
        <div style={{ marginTop: 10 }}>Please wait . . .</div>
      </div>
    );
  }
}

export default BulkPrinter;
