export default function formatObjectKeys (rawData: { [key: string]: any }) {
  let processedData: any = {};
  let dataKeys: Array<any> = [];
  Object.keys(rawData).map(key => {
    dataKeys.push(snakeToCamel(key));
  });
  let i = 0;
  for (let index in rawData) {
    processedData[dataKeys[i]] = rawData[index]
    i++
  }
  return processedData;
}

export function snakeToCamel (snakeString: string) {
  return snakeString.replace(/(_\w)/g, (snakeString) => {
    return snakeString[1].toUpperCase();
  });
}
