import * as React from "react";
import * as Barcode from "react-barcode";
import QRCode from "qrcode.react";

import "./Label.css";
import formatObjectKeys from "../../../../helpers/formatObjectKeys";
import { formatDateTime } from "../../../../helpers/formatDate";

// Kode Barcode SiCepat
export const customerSMCode = "05SM"; 
export const SMCode = "CNX";

export function getLogo(logisticsProvider: string) {
  return require(`../../../../images/courier-logos/${logisticsProvider}-logo.png`);
}

export function Label (data: any) {
  const { 
    orderID,
    senderName,
    senderPhone,
    receiverName,
    receiverPhone,
    receiverAddress,
    receiverPostCode,
    description ,
    airwaybillNumber,
    courierCode,
    shipmentReference,
    timestamp,
    shipmentCode
  } = formatObjectKeys(data.data);

  return (
    <div className="label-container" >
      <style>
        {`
          @media print { 
            html, body: { width: 384px; height: 384px; } 
            @page { 
              size: 384px 384px; margin: 0mm; padding: 0;
            } 
          }
        `}
      </style>
      <div className="label-area">
        <div className="label-top-content"> 
          <div className="label-header">
            LOGISTIX
            <div className="label-ship-detail">
              <img className="label-shipment-logo" src={getLogo(courierCode.split(" ")[0].toLowerCase())}/>
            </div>
          </div>
          <div className="label-top-barcode-container">
            <div className="label-top-barcode">
              <Barcode
                format="CODE39" 
                value={airwaybillNumber} 
              />
            </div>
          </div>
        </div>
        <div className="label-content">
          <div className="label-content-detail">
            <div className="label-seller-detail">
              <span>
                Order Date: {formatDateTime(timestamp)}
              </span>
              <span>
                SELLER: <b>{senderName.toUpperCase()}</b>
              </span>
              <span>
                Telp. {senderPhone}
              </span>
            </div>
            <div className="label-content-ship-detail">
              <span>
                SHIP TO: <b>{receiverName.toUpperCase()}</b>
              </span>
              <span>
                Telp. {receiverPhone}
              </span>
              <span>
                {receiverAddress} {receiverPostCode}
              </span>
            </div>
          </div>
          <div className="label-qr-code">
            {/* <span className="label-qr-code-head"/> */}
            <p className="shipment-code">
              {shipmentCode}
            </p>
            <span><b>Detail Goods:</b></span>
            <span>{description}</span>
            <div className="label-qr-code-container">
              {
                courierCode.split(" ")[0] === "sicepat" ? (
                  <QRCode 
                    renderAs="svg" 
                    value={`
                      ${customerSMCode} 
                      ${orderID}
                      ${receiverName}
                      ${receiverAddress}
                      ${receiverPhone}
                      ${senderName}
                      ${senderPhone}
                      ${courierCode.split(" ")[1]}
                      ${airwaybillNumber}
                      ${SMCode}
                    `} 
                    size={100} />
                ) : (
                  null
                )
              }
            </div>
          </div>
        </div>
        <div className="label-bottom-barcode-container">
          <Barcode 
            height={50} 
            format="CODE128"
            value={shipmentReference}
          />
        </div>
      </div>
    </div>
  );
};
