import React, { Component } from "react";
import { Button, TextField, Modal, withStyles } from "@material-ui/core";

import fetchJSON from "../../../helpers/fetchJSON";

import "./CompleteOrder-style.css";
import { getModalStyle, styles } from "./CompleteOrderStyle";

type CompleteOrderProps = {
  classes: any,
}
type CompleteOrderState = {
  textInput: string,
  isModalOpen: boolean,
  modalMessage: string,
  isLoading: boolean,
}

class CompleteOrder extends Component<CompleteOrderProps, CompleteOrderState> {

  state: CompleteOrderState = {
    textInput: "",
    isModalOpen: false,
    modalMessage: "",
    isLoading: false,
  }

  async completeOrder(event: any) {
    event.preventDefault();
    let body =  {
      shipment_reference: `${this.state.textInput}`,
      status: "completed",
    };
    await this.setState({ isLoading: true });

    try {
      let result = await fetchJSON(`/createAWB/updateStatus`, {
         method: "post",
         body: JSON.stringify(body),
      });
      if (result) {
        if (result.message === "success") {
          this.setState({ 
            modalMessage: 
              `Anda BERHASIL menyelesaikan pesanan 
              dengan Shipment Reference ${this.state.textInput}`,
            isModalOpen: true,
            isLoading: false, 
          });
        } else {
          this.setState({ 
            modalMessage: `GAGAL Menyelesaikan Pesanan. Error Message: ${result.message}`,
            isModalOpen: true,
            isLoading: false,  
          });
        }
      } else {
        this.setState({ 
          modalMessage: `No Data`, 
          isModalOpen: true,
          isLoading: false, 
        });
      }
    } catch(err) {
      this.setState({ 
        modalMessage: `GAGAL Menyelesaikan Pesanan. Error Message: ${err}`,
        isModalOpen: true,
        isLoading: false, 
      })
    }
  }

  handleClose() {
    this.setState({ isModalOpen: false });
  };

  render() {
    return (
      <div className="complete-order-container">
        <form onSubmit={(event) => this.completeOrder(event)} className="complete-order-content ">
          <TextField
            variant="outlined"
            onChange={(event: any) => this.setState({ textInput: event.target.value})}
            label="Shipment Reference"
            required={true}
          />
          <Button 
            disabled={this.state.isLoading}
            type="submit"
            color="primary"
            variant="contained"
            style={{maxHeight: 56}}
          >
            Complete Order
          </Button>
        </form> 

        <Modal
          open={this.state.isModalOpen}
          onClose={() => this.handleClose()}
        >
          <div style={getModalStyle()} className={this.props.classes.paper}>
              {this.state.modalMessage}
          </div>
        </Modal>
      </div>
    );
  }
}

export default withStyles(styles)(CompleteOrder);