import * as React from "react";
import { Modal, withStyles } from "@material-ui/core";

import { Label } from "./Label";
import Airwaybill, { airwaybillData } from "./Airwaybill";
import { 
  getModalStyle, 
  styles 
} from "../PickOrderStyle";

import fetchJSON from "../../../../helpers/fetchJSON";
import { ProcessedResponse } from "../../types/response";
import formatObjectKeys from "../../../../helpers/formatObjectKeys";
import AirwaybillJNE from "./awbTypes/AirwaybillJNE";

type PrinterProps = {
  location: any,
  classes: any,
}

type PrinterState = {
  printed: boolean;
  data?: ProcessedResponse | airwaybillData | any; // TO DO: change to proper typing
  awbData?: any
  error: boolean;
  errorMessage: string;
}

class LabelPrinter extends React.Component<PrinterProps, PrinterState> {
  state: PrinterState = { 
    printed: false,
    error: false,
    errorMessage: "",
  };

  pathname = this.props.location.pathname.split("/");

  async componentDidMount() {
    // This pattern is used because somehow in Chrome, this method is called twice (hence the "printed" state),
    // and the render is not fully finished before window.print() is in effect (hence the timeout).
    if (this.pathname[3]) {
      try {
        let result = await fetchJSON(`/createAWB/${this.props.location.pathname.split("/")[3]}`);
        if(result) {
          if (Object.keys(result).length > 1) {
            this.setState({ data: result });
          } else {
            this.setState({ errorMessage: `Terjadi Kesalahan, Error Message: ${result.message}`, error: true });
          }
        } else {
          this.setState({ errorMessage: `Terjadi kesalahan, Error Message: ${result}`, error: true });
        }
      } catch(err) {
        this.setState({ errorMessage: `Terjadi Kesalahan, Error Message: ${err}`, error: true });
      }
    } else {
      let opener = window.opener;
      opener && opener.postMessage("request_data", "*");
      window.addEventListener("message", (e) => {
        let processedData = formatObjectKeys(JSON.parse(e.data));
        this.setState({ data: processedData });
      });
    }
    if (!this.state.printed) {
      setTimeout(() => window.print(), 500);
      this.setState( { printed: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", (e) => {
      this.setState({ data: e.data });
    });
  }

  handleClose() {
    this.setState({ error: false });
  }; 

  render() {
    const { 
      data, 
      error, 
      errorMessage 
    } = this.state;

    if (data) {
      if (this.pathname[3]) {
        return (
          <>
            {
              Object.keys(data).length !== 0 && !error ? (
                <Label data={data}/>
              ) : (
                <Modal
                  open={error}
                  onClose={() => this.handleClose()}
                >
                  <div style={getModalStyle()} className={this.props.classes.paper}>
                    {errorMessage}
                  </div>
                </Modal>
              )
            }
          </>
        )
      }
      if (data.providerName === "JNE") {
        return <AirwaybillJNE data={data}/>
      }
      return <Airwaybill data={data} />
    } 
    return "Terjadi Kesalahan"
  }
}

export default withStyles(styles)(LabelPrinter);