import React from "react";
import * as Barcode from "react-barcode";
import QRCode from "qrcode.react";

import formatObjectKeys from "../../../../helpers/formatObjectKeys";

import "./Airwaybill.css";

type recipientData = {
  name: string;
  address: string;
  phoneNumber: string;
  district: string;
  postCode: string;
};

export type productLineData = {
  price: number;
  productCode: string;
  productFid: string;
  quantity: number;
  title: string;
};

type customSpan = React.HTMLAttributes<HTMLSpanElement> & {
  flex: number;
};

export type airwaybillData = {
  orderDate: string;
  recipientData: recipientData;
  orderNumber: string;
  providerName: string;
  providerService: string;
  awbNumber: string;
  senderName: string;
  senderPhone: string;
  remarks: string;
  message: string;
  products: productLineData[];
  logo: string;
  customerSMCode: string; // cnx's code: 05SM
  smCode: string; // cnx's code CNX
  extraAttributes?: {
    CustomerSMCode?: string;
    SMCode?: string;
    isDropship?: boolean;
  };
};

type airwaybillProp = {
  data: airwaybillData;
};

// NOTES
/*
  J&T CODE128
  ALL: CODE39

  HEIGHT FACTOR ALL: 1
  HEIGHT FACTOR J&T: 0.55

*/

const Span = (props: customSpan) => (
  <span {...props} style={{ flex: props.flex, ...props.style }} />
);

const ProductLine = (product: productLineData, index: number) => {
  return (
    <div key={index} className="table-content-row">
      <Span flex={2}>{index + 1}</Span>
      <Span flex={5}>{product.productCode}</Span>
      <Span flex={10}>{product.title}</Span>
      <Span flex={6} />
      <Span flex={2}>{product.quantity}</Span>
      {/* {Component(products)} */}
    </div>
  );
};

// TO DO: Enable component when products have component items
// const Component = (allComponent: any) => {
//   return allComponent.map((comp: any, key: number) => {
//     return (
//       <>
//         <div className="table-content-row">
//           <Span flex={2}>{key}</Span>
//           <Span flex={5}>{comp.productCode}</Span>
//           <Span flex={10}>{comp.productFid}</Span>
//           <Span flex={6}></Span>
//           <Span flex={2}>{comp.quantity}</Span>
//         </div>
//         {/* {
//           comp.var ? (
//             comp.var.map((variant: any, key: number) => {
//               return (
//                 <div key={key} className="table-content-row">
//                   <Span flex={2}></Span>
//                   <Span flex={5}></Span>
//                   <Span flex={10}>{variant.number}</Span>
//                   <Span flex={6}>{variant.name}</Span>
//                   <Span flex={2}>{variant.qty}</Span>
//                 </div>
//               );
//             })
//           ) : null
//         } */}
//       </>
//     );
//   })
// }

const SellerDetails = (props: airwaybillData) => {
  const { orderDate, senderName, senderPhone } = props;

  return (
    <div className="flex-column">
      <span>Order Date: {orderDate}</span>
      <span>SELLER: {senderName}</span>

      {/* <span className="large-text-bold">
        BARU
      </span> */}
      <span>Telp. {senderPhone}</span>
      <span>Airway Bill</span>
    </div>
  );
};

const BuyerDetails = (recipientData: recipientData) => {
  return (
    <>
      <span>SHIPPING ADDRESS:</span>
      <span className="large-text-bold">{recipientData.name}</span>
      <span>{recipientData.address}</span>
      <span>
        {recipientData.district} {recipientData.postCode}
      </span>
      <span>Telp. {recipientData.phoneNumber}</span>
    </>
  );
};

export default function Airwaybill(props: airwaybillProp) {
  const {
    recipientData,
    orderNumber,
    providerName,
    providerService,
    awbNumber,
    senderName,
    senderPhone,
    remarks,
    message,
    products,
    logo,
    customerSMCode,
    smCode,
    extraAttributes,
  } = props.data;
  const now = new Date();

  return (
    <div className="container">
      <div className="header">
        <span className="header-text">
          Print date: #{now.getDate()}/{now.getMonth() + 1}/{now.getFullYear()}{" "}
          {now.getHours()}:{now.getMinutes()}#
        </span>
      </div>
      <div className="print-area">
        <div className="flex-row-grow">
          <div className="print-content">
            <span style={{ display: "flex", height: 84 }}>
              {!(extraAttributes && extraAttributes.isDropship) && (
                <>
                  <img
                    style={{
                      margin: "0px 10px 0px 0px",
                      maxHeight: 74,
                      maxWidth: 185,
                      objectFit: "contain",
                    }}
                    src={require("../../../../images/srcl-logo.png")}
                  />
                  <img
                    style={{
                      margin: "0px 10px 0px 0px",
                      maxHeight: 74,
                      maxWidth: 140,
                      objectFit: "contain",
                    }}
                    src={logo}
                  />
                </>
              )}
            </span>
            <div style={{ lineHeight: 1 }}>Order Number {orderNumber}</div>
            <Barcode
              width={1.35}
              format="CODE39"
              displayValue={true}
              value={orderNumber}
            />
            {SellerDetails(props.data)}
            <Barcode
              width={1.25}
              format="CODE39"
              displayValue={true}
              value={awbNumber}
            />
          </div>
          <div className="print-content">
            <span
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                height: 84,
              }}
            >
              <img
                alt="Courier Logo"
                width={80}
                src={
                  providerName
                    ? require(`../../../../images/courier-logos/${`${providerName}`.toLowerCase()}-logo.png`)
                    : null
                }
                // This code is used to check sicepat logo quickly
                // src={require(`../../../../images/courier-logos/sicepat-logo.png`)}
              />
            </span>
            <div style={{ lineHeight: 1 }}>SHIPMENT PROVIDER:</div>
            <span className="shipment-name">
              {providerName} {providerService}
            </span>
            <div style={{ flexDirection: "row", display: "flex" }}>
              Insurance Fee: Rp0
            </div>
            <div className="flex-grow-column">
              {recipientData
                ? BuyerDetails(formatObjectKeys(recipientData))
                : "Tidak ada data penerima"}
              {/* TO DO: Re-enable when data is ready and confirmed */}
              {/* <span style={{marginTop: 20}}><b>Detail Goods</b>: laptop</span> */}
            </div>
          </div>
        </div>
        <div style={{ flexDirection: "row", display: "flex" }}>
          <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
            {`${providerName}`.toLowerCase() === "sicepat" ? (
              <QRCode
                renderAs="svg"
                value={`
                    ${customerSMCode}~^~
                    ${orderNumber}~^~
                    ${recipientData.name}~^~
                    ${recipientData.address}~^~
                    ${recipientData.phoneNumber}~^~
                    ${senderName}~^~
                    ${senderPhone}~^~
                    ${providerService}~^~
                    ${awbNumber}~^~
                    ${smCode}
                  `}
              />
            ) : null}
          </div>
        </div>
        <div className="thank-you">THANK YOU FOR SHOPPING WITH US</div>
      </div>
      <div className="print-area" style={{ borderTop: 0 }}>
            <div className="print-area-notes">
              NOTES: {remarks}<br/>
              BUYER NOTES: {message}
            </div>
        <div className="table-container">
          <div className="table-header">
            <Span flex={2}>
              <b>No</b>
            </Span>
            <Span flex={5}>
              <b>SKU</b>
            </Span>
            <Span flex={10}>
              <b>Name</b>
            </Span>
            <Span flex={6}>
              <b />
            </Span>
            <Span flex={2}>
              <b>Qty</b>
            </Span>
          </div>
          <div className="table-content">
            {products
              ? products.map((product, index: number) => {
                  return ProductLine(formatObjectKeys(product), index);
                })
              : "Tidak ada data produk"}
          </div>
        </div>
      </div>
    </div>
  );
}
