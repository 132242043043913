import formatObjectKeys from "./formatObjectKeys";

export const isOrderInvalid = (data: Object) => {
  if (data) {
    if (Object.keys(data).length !== 0) {
      if (formatObjectKeys(data).status === ( "completed" || "picked")) {
        return true;
      } else return false;
    } else return false;
  } else return false;
}
