import React, { Component } from "react";
import { 
  TextField, 
  Button, 
  Modal, 
  withStyles
 } from "@material-ui/core";

import { Label } from "./printer/Label";
import fetchJSON from "../../../helpers/fetchJSON";

import "./PickOrder-style.css";
import { ProcessedResponse } from "../types/response";
import { getModalStyle, styles } from "./PickOrderStyle";
import { isOrderInvalid } from "../../../helpers/isOrderInvalid";

type PickOrderState = {
  textInput: string,
  dataReceived: boolean,
  isModalOpen: boolean,
  modalMessage: string,
  data: ProcessedResponse | object, // TO DO: replace with proper response typing
  isLoading: boolean,
}

class PickOrder extends Component<any, PickOrderState> {

  state = {
    textInput: "",
    dataReceived: false,
    isModalOpen: false,
    modalMessage: "",
    data: {},
    isLoading: false,
  }

  async submitForm(e: any) {
    e.preventDefault();
    await this.setState({ isLoading: true });
    try {
      let result = await fetchJSON(`/createAWB/${this.state.textInput}`);
      if (result) {
        if (Object.keys(result).length > 1) {
          this.setState({ 
            data: result, 
            dataReceived: true, 
            isLoading: false 
          });
        } else {
          this.setState({ 
            modalMessage: `Terjadi Kesalahan, Error Message: ${result.message}`, 
            isModalOpen: true, 
            isLoading: false 
          });
        }
      } else {
        this.setState({ 
          modalMessage: `No Data`, 
          isModalOpen: true, 
          isLoading: false 
        });
      }
    } catch(err) {
      this.setState({ 
        modalMessage: `Terjadi Kesalahan, Error Message: ${err}`, 
        isModalOpen: true, 
        isLoading: false 
      });
    }
  }

  async pickupItem() {
    await this.setState({ isLoading: true });
    let body =  JSON.stringify({
      shipment_reference: `${this.state.textInput}`,
      status: "picked",
    });
    try {
      let result = await fetchJSON(`/createAWB/updateStatus`, {
        method: "post",
        body,
      });
      if (result.message === "success") {
        this.setState({ 
          modalMessage: 
            `Anda BERHASIL mengambil pesanan 
            dengan Shipment Reference ${this.state.textInput}`,
          isModalOpen: true, 
          isLoading: false 
        });
      } else {
        this.setState({ 
          modalMessage: `GAGAL mengambil Pesanan. Error Message: ${result.message}`,
          isModalOpen: true, 
          isLoading: false 
        });
      }
    } catch(err) {
      this.setState({ 
        modalMessage: `GAGAL Mengambil Pesanan. Error Message: ${err}`,
        isModalOpen: true, 
        isLoading: false
      })
    }
  }

  handleClose() {
    this.setState({ isModalOpen: false });
  };

  render() {
    const { 
      dataReceived,
      textInput,
      data,
    } = this.state;

    return (
      <>
        <div className="pick-order-container">
          <form onSubmit={(e) => this.submitForm(e)} className="label-input-content">
            <TextField
              variant="outlined"
              onChange={(event: any) => this.setState({ textInput: event.target.value})}
              label="Shipment Reference"
              required={true}
            />
            <Button 
              disabled={this.state.isLoading || this.state.dataReceived}
              type="submit"
              color="primary"
              variant="contained"
              style={{maxHeight: 56}}
            >
              Preview Label
            </Button>
          </form> 
          {
            dataReceived ? (
              <>
                <div className="preview-label-container">
                  <Label data={data}/>
                </div>
                <div className="label-buttons-container">
                  <Button 
                    onClick={() => window.open(`/print/label/${textInput}`)} 
                    color="primary"
                    variant="contained"
                    fullWidth
                  >
                    Cetak Label
                  </Button>
                  <div className="divider" />
                  <Button
                    onClick={() => this.pickupItem()}
                    color="secondary"
                    variant="contained"
                    fullWidth
                    disabled={isOrderInvalid(data)}
                  >
                    Pickup Barang
                  </Button>
                </div>
              </>
            ) : null
          }
          <Modal
            open={this.state.isModalOpen}
            onClose={() => this.handleClose()}
          >
            <div style={getModalStyle()} className={this.props.classes.paper}>
                {this.state.modalMessage}
            </div>
          </Modal>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(PickOrder);