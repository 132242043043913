import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import "./MainContainer.css";

type MENU_TYPE = "pickorder" | "completeorder";

class MainContainer extends Component<any, any> {

  render() {
    const { pathname } = this.props.location;
    const { getActivePath } = this;
    
    return !this.props.history.location.pathname.startsWith("/print") ? (
      <div className="main-wrapper-content">
        <div className="side-bar-container">
          <div onClick={() => this.props.history.push("/")} className="side-bar-logo-container">
            <span className="logo-text">LOGISTIX</span>
          </div>
          <div className="side-bar-menu">
            {this.MainMenu(/pickorder/, "/pickorder", "pickorder")}
            {this.MainMenu(/completeorder/, "/completeorder", "completeorder")}
          </div>
        </div>
        <div className="top-header">
          {getActivePath(pathname.split("/")[1])}
        </div>
        <div className="main-content">
          {this.props.children}
        </div>
      </div>
    ) : (
      <div className="main-wrapper-content">
        {this.props.children}
      </div>
    )
  } 

  getActivePath(type: string) {
    switch (type) {
      case "pickorder": {
        return "Ambil Pesanan";
      } 
      case "completeorder": {
        return "Selesaikan Pesanan"; 
      }
      default: return "";
    }
  }

  MainMenu(regexCondition: RegExp, to: string, type: MENU_TYPE) {
    const { pathname } = this.props.location;
    const { getActivePath } = this;
    let text = getActivePath(type);
    return (
      <Link style={{ textDecoration: "none" }} to={to}>
        <div className={`side-bar-menu-item ${regexCondition.test(pathname) && "selected"}`}>
          <span>{text}</span>
        </div>
      </Link>
    );
  }
}

export default withRouter<any>(MainContainer);
