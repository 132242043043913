  import React, { Component } from "react";

import "./Home.css";
import { withRouter } from "react-router";

class Home extends Component<any, any> {
  render() {
    return (
      <div>
        Ini Halaman Home
      </div>
    );
  }
}

export default withRouter<any>(Home);